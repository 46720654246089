:root {
    --almost-black: #404040;
    --blue: #004b8a;
    --light-blue: #e1efff;
    --light-grey: #f1f0f2;
    --medium-grey: #b8b8b8;
    --dark-grey: #797979;
    --red: #db0d15;
    --yellow: #ffd700;

    --color-header-background: var(--light-grey);
    --color-background-code: rgba(0, 0, 0, 0.35);
    --color-background-default: #081120;
    --color-button-background: #2a3343;
    --color-button-background-hover: #454950;
    --color-button-border: #18181a;
    --color-code: #c67bff;
    --color-default: #ffffff;
    --color-horizontal-rule: #454950;
    --color-link: #dcadff;
    --color-panel-background: #e5e5e5;
    --color-panel-background-alternate: #202124;
    --color-panel-title-background: var(--light-grey);
    --color-solid-resize-bar: #454950;
    --color-solid-resize-bar-handle: rgba(50, 55, 55, 0.2);
}

.quill {
    display: flex;
    flex-direction: column-reverse;
}

.quill .ql-editor {
    min-height: 200px;
}

.quill .ql-container.ql-snow {
    border-top-width: 1px;
    border-top-left-radius: calc(var(--radius) - 2px);
    border-top-right-radius: calc(var(--radius) - 2px);
    border-bottom: 0 !important;
    background-color: rgb(243 244 246 / 1);
    flex-grow: 1;
    border-color: hsl(var(--input));
    font-size: 1rem;
}

.quill .ql-toolbar.ql-snow {
    border-bottom-left-radius: calc(var(--radius) - 2px);
    border-bottom-right-radius: calc(var(--radius) - 2px);
    background-color: rgb(243 244 246 / 1);
    border-color: hsl(var(--input));
}

.text-formatted h1 {
    font-weight: bold;
    font-size: 2em;
    line-height: 1.5;
}

.text-formatted h2 {
    font-weight: bold;
    font-size: 1.5em;
    line-height: 1.5;
}

.text-formatted h3 {
    font-weight: bold;
    font-size: 1.2em;
    line-height: 1.5;
}

.text-formatted ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}

.text-formatted ul li {
    display: list-item;
}

.text-formatted ol {
    display: block;
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}

.text-formatted ol li {
    display: list-item;
}

.quill .ql-editor ol, .quill .ql-editor ul {
    margin-top: 1em;
    margin-bottom: 1em;
}


.files ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}

.files ul li {
    display: list-item;
}



.filepond-grid .filepond--item {
    width: calc(100% - 0.5em);
}

@media (min-width: 30em) {
    .filepond-grid .filepond--item {
        width: calc(50% - 0.5em);
    }
}

@media (min-width: 50em) {
    .filepond-grid .filepond--item {
        width: calc(33.33% - 0.5em);
    }
}

input[type='number'] {
    appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button,
input[type='number']:hover::-webkit-inner-spin-button,
input[type='number']:hover::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
